<template>
  <section class="invoice-add-wrapper">
    <h5>Add a product</h5>
    <b-row class="invoice-add">

      <!-- Col: Left (product Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <!-- Body -->
        <b-form @submit.prevent>
          <b-row>

            <!-- first name -->
            <b-col cols="12">
              <b-form-group
                  label="Display Name"
                  label-for="v-display-name"
              >
                <b-form-input
                    id="v-display-name"
                    placeholder="Product Name"
                    v-model="productData.displayName"
                />
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <b-form-group
                  label="Model"
                  label-for="v-model"
              >
                <b-form-input
                    id="v-model"
                    placeholder="Model"
                    v-model="productData.model"
                />
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col cols="12">
              <b-form-group
                  label="Description"
                  label-for="v-description"
              >
                <b-form-textarea
                    id="v-description"
                    placeholder="Product description"
                    v-model="productData.description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Price"
                  label-for="v-price"
              >
                <b-form-input
                    id="v-price"
                    placeholder="0"
                    type="number"
                    v-model="productData.price"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="Stock"
                  label-for="v-stock"
              >
                <b-form-input
                    id="v-stock"
                    placeholder="0"
                    v-model="productData.stock"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="SKU"
                  label-for="v-sku"
              >
                <b-form-input
                    id="v-sku"
                    placeholder="SKU"
                    v-model="productData.SKU"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="URL"
                  label-for="v-url"
              >
                <b-form-input
                    id="v-url"
                    placeholder="https://example.com/products/abc"
                    v-model="productData.url"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="External Id"
                  label-for="v-external-id"
              >
                <b-form-input
                    id="v-external-id"
                    placeholder=""
                    v-model="productData.externalId"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="Provider"
                  label-for="v-provider"
              >
                <b-form-input
                    id="v-provider"
                    placeholder="Brand name, or manufacturer name"
                    v-model="productData.provider"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="addProduct()"
              >
                Submit
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import {
  BRow, BCol, BForm, BFormTextarea, VBToggle, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [],
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
  },
  setup() {
    // const {
    //   validateProduct,
    // } = useProductHandler()

    const productData = ref({
      displayName: '',
      model: '',
      description: '',
      SKU: '',
      url: '',
      externalId: '',
      provider: '',
      price: 0,
      stock: -1,
      active: true,
      metadata: {},
    })

    const addProduct = () => {
      // Get product  id from URL
      store.dispatch('app-ecommerce/addProduct', { product: productData.value })
        .then(response => {
          window.alert('Successfully added product')
          router.push({ name: 'apps-e-commerce-product-edit', params: { id: response.data.id } })
        })
        .catch(error => {
          if (error.response.status === 404) {
            productData.value = undefined
          }
        })
    }

    return {
      productData,
      addProduct,
    }
  },
}
</script>
